
import {defineComponent, onMounted, onUnmounted} from "vue";
import {useStore} from "vuex";
import {Actions} from "../../store/enums/StoreEnums";
import {getIllustrationsPath} from "../../core/helpers/assets";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {reactive} from "vue";
import {ref, unref} from "vue";
import {DateTimeUtils} from "@/latipay/utils/DateTimeUtils";
import {reinitializeComponents} from "@/core/plugins/keenthemes";
import {EnumsConstant} from "@/store/enums/constants";
import {
  approveMerchantSettlementRequest,
  getMerchantSettlementsRequest, rejectMerchantSettlementRequest
} from "@/latipay/apis/services/MerchantSettlementsService";
import {ElMessage} from "element-plus";
import {ElMessageBox} from 'element-plus'
import {getAccountBalanceRequest} from "@/latipay/apis/services/AccountBalanceService";
import {getSendersRequest} from "@/latipay/apis/services/SenderService";
import {getQuoteRequest} from "@/latipay/apis/services/QuoteService";
import {getRecipientsRequest} from "@/latipay/apis/services/RecipientService";
import router from "@/router";
import {postAccountSourceRequest} from "@/latipay/apis/services/AccountSourcesService";
import {postSendFundRequest} from "@/latipay/apis/services/SendFundService";
import {AddAccountSource} from "@/latipay/apis/models/accountSource/AccountSourcesResponse";
import {AddSendFund} from "@/latipay/apis/models/sendFund/SendFundResponse";
import {getSubclientRequest} from "@/latipay/apis/services/SubclientService";

export default defineComponent({
  name: "internationalPayout",
  components: {},
  setup() {
    const store = useStore();
    const sort = ref<string | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(1000);

    const stepOneFormRef = ref();
    const stepTwoFormRef = ref();
    const stepThirdFormRef = ref();
    // const stepFourFormRef = ref();
    const stepIndex = ref(0);
    const clearedAUD = ref(0.00);
    const pendingAUD = ref(0.00);
    const loadingRef = ref(false);
    const refreshPending = ref(false);
    const sendFundSuccess = ref(false);
    const showSend = ref(false);
    const currencies = EnumsConstant.CurrencyCode;
    const dialogVisible = ref(false)
    const cfgSenderValue = ref(false)
    const activeIndex = ref('0')


    const stepOneForm = reactive({
      sendAmount: "200.000",
      receivedAmount: "",
      receivedCurrency: "EUR"
    });

    const stepTwoForm = reactive({
      senderId: "",
      balanceType: "",
      amount: "",
      reason: "",
      subclientId: ""
    });

    const stepThirdForm = reactive({
      recipientId: "",
      paymentReason: "",
      sourceOfFund: "",
      paymentReference: ""
    });

    const successAlert = (message => {
      ElMessage({
        showClose: true,
        message: message,
        type: "success"
      });
    });

    const failAlert = (message => {

      ElMessage.error(message)
    });

    const handleReceivedAmountChange = async (value: number) => {
      loadingRef.value = true;
      console.log(value)
      stepOneForm.receivedAmount = Number(value).toFixed(3);
      await getQuoteRef(stepOneForm.receivedCurrency, stepOneForm.receivedCurrency, stepOneForm.receivedAmount.toString());
      stepOneForm.sendAmount = quoteRef.value ? Number(quoteRef.value.fromAmount).toFixed(3) : ''
      console.log(quoteRef)
      loadingRef.value = false;
    }
    const handleSendAmountChange = async (value: number) => {
      console.log(value)
      loadingRef.value = true;
      stepOneForm.sendAmount = Number(value).toFixed(3)

      await getQuoteRef('AUD', stepOneForm.receivedCurrency, stepOneForm.sendAmount.toString());
      stepOneForm.receivedAmount = quoteRef.value ? Number(quoteRef.value.toAmount).toFixed(3) : ''
      console.log(quoteRef)
      loadingRef.value = false;
    }

    const handleSelectCurrency = async (value: string) => {
      console.log(value)
      loadingRef.value = true;
      // stepOneForm.sendAmount = Number(value).toFixed(2)
      if (stepOneForm.sendAmount) {

        await getQuoteRef('AUD', stepOneForm.receivedCurrency, stepOneForm.sendAmount.toString());
        loadingRef.value = false;
      } else {
        await getQuoteRef('AUD', stepOneForm.receivedCurrency, "200");
        loadingRef.value = false;
      }
      stepOneForm.receivedAmount = quoteRef.value ? Number(quoteRef.value.toAmount).toFixed(3) : ''
      console.log(quoteRef)

    }

    const {
      getQuoteRef,
      errorRef: getQuoteErrorRef,
      quoteRef
    } = getQuoteRequest();


    const {
      getAccountBalanceRef,
      responseRef, errorRef,
      getAccountBalance
    } = getAccountBalanceRequest();


    const onSubmit = () => {
      console.log("submit!");
    };


    onMounted(async () => {
      // setCurrentPageTitle("Users");
      await getBalance();

      await getQuoteRef('AUD', stepOneForm.receivedCurrency, stepOneForm.sendAmount.toString());
      stepOneForm.receivedAmount = quoteRef.value ? Number(quoteRef.value.toAmount).toFixed(3) : ''


    });

    const getBalance = async () => {
      loadingRef.value = true;

      // await updateQueries();
      await getAccountBalance();
      reinitializeComponents();

      loadingRef.value = false;
      // clearedAUD.value = getAccountBalanceRef ? getAccountBalanceRef;
      console.log(getAccountBalanceRef);
    };


    const updatePageSize = async (val: number) => {
      console.log(`${val} items per page`);
      pageSize.value = val;

    };
    const updatePageNumber = async (val: number) => {
      console.log(`current page: ${val}`);
      pageNumber.value = val;

    };

    const handleSelect = (key: string, keyPath: string[]) => {
      console.log(key, keyPath)
      if (+key === 1) {
        showSend.value = true;
      } else {
        showSend.value = false;
      }
    }


    const showSendForm = () => {
      console.log("showSendForm!");
      showSend.value = true;
    };


    const refreshBalance = async () => {
      console.log("showSendForm!");
      refreshPending.value = true;
      await getAccountBalance();
      reinitializeComponents();
      refreshPending.value = false;
    };


    const onToStepTwo = async () => {
      console.log("tttt")
      const form = unref(stepOneFormRef);
      if (!form) {

        return
      }

      console.log("vvvvv")
      try {
        await form.validate();
        if (stepOneForm.sendAmount && stepOneForm.receivedCurrency && stepOneForm.receivedAmount) {
          stepIndex.value = 1;
          console.log("fffff");
          await getSenderData();
          await getSubclientData();


        }
      } catch (err) {
        console.log(err)
      }

    };


    const {
      getSendersRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      getSenders
    } = getSendersRequest();


    const getSenderData = async () => {
      loadingRef.value = true;

      // await updateQueries();
      await getSenders(
          sort.value,
          pageNumber.value,
          pageSize.value
      );
      reinitializeComponents();

      loadingRef.value = false;
      console.log("getSendersRef");
      console.log(getSendersRef);
    };

    const {
      getSubclientsRef,
      totalRef: subclientTotalRef,
      pageNumberRef: subclientPageNumberRef,
      pageSizeRef: subclientPageSizeRef,
      getSubclients
    } = getSubclientRequest();


    const getSubclientData = async () => {
      loadingRef.value = true;

      // await updateQueries();
      await getSubclients(
          sort.value,
          pageNumber.value,
          pageSize.value
      );
      reinitializeComponents();

      loadingRef.value = false;
      console.log("getSubclientsRef");
      console.log(getSubclientsRef);
    };

    const onToStepThree = async () => {


      const form = unref(stepTwoFormRef);
      if (!form) {
        return
      }
      try {
        await form.validate();
        if (stepTwoForm.senderId || stepTwoForm.subclientId || cfgSenderValue.value) {
          stepIndex.value = 2
          await getRecipientData();


        } else {
          failAlert("Please choose a sender or a subclient!");
        }
      } catch (err) {
        console.log(err)
      }

    };


    const {
      getRecipientsRef,
      totalRef: recipientTotalRef,
      pageNumberRef: recipientPageNumberRef,
      pageSizeRef: recipientPageSizeRef,
      getRecipients
    } = getRecipientsRequest();


    const getRecipientData = async () => {
      loadingRef.value = true;

      // await updateQueries();
      await getRecipients(
          sort.value,
          pageNumber.value,
          pageSize.value
      );
      reinitializeComponents();

      loadingRef.value = false;
      console.log("getRecipientsRef");
      console.log(getRecipientsRef);
    };

    const onToStepFour = async () => {


      const form = unref(stepThirdFormRef);
      if (!form) {
        return
      }
      try {
        await form.validate();
        if (stepThirdForm.recipientId && stepThirdForm.sourceOfFund && stepThirdForm.paymentReason) {
          stepIndex.value = 3


        } else {
          failAlert("Input amount must be larger than 0.");
        }
      } catch (err) {
        console.log(err)
      }

    };

    const {postErrorRef, postSendFundRef, postSendFund} = postSendFundRequest();


    const sendFund = async () => {
      loadingRef.value = true;
      console.log("ffrfr")
      const newSendFund = new AddSendFund();

      newSendFund.fromCurrency = "AUD"
      newSendFund.toCurrency = stepOneForm.receivedCurrency
      newSendFund.amount = +stepOneForm.sendAmount
      newSendFund.currency = "AUD"
      newSendFund.quoteId = quoteRef.value ? quoteRef.value.id : ''
      newSendFund.reason = stepThirdForm.paymentReason
      newSendFund.sourceOfFunds = stepThirdForm.sourceOfFund
      newSendFund.senderId = stepTwoForm.senderId
      newSendFund.subClientId = stepTwoForm.subclientId
      // newSendFund.subClientId = "631eb0ff966ae6f807e692a5"
      // newSendFund.senderId = ""
      newSendFund.recipientId = stepThirdForm.recipientId
      newSendFund.externalReference = stepThirdForm.paymentReference
      newSendFund.externalId = ""

      newSendFund.callbackUri = ""

      console.log(newSendFund)

      await postSendFund(newSendFund);

      if (!postErrorRef.value) {

        console.log("333333" + postErrorRef.value)
        stepIndex.value = 4;
        loadingRef.value = false;
        sendFundSuccess.value = true;
        refreshBalance()


      } else {
        failAlert("Something wrong happened! Please have a check.");
        refreshBalance()
      }


    };

    const cancelSend = async () => {

      console.log("ffrfr")
      dialogVisible.value = true;


    };
    const handleChooseSender = async () => {

      console.log("ffrfr")
      stepTwoForm.subclientId = ""
      cfgSenderValue.value = false

    };
    const handleChooseSubclient = async () => {

      console.log("ffrfr")
      cfgSenderValue.value = false
      stepTwoForm.senderId = ""
    };

    const  handleChooseCFG = async () => {

      console.log(cfgSenderValue.value)

      stepTwoForm.senderId = ""
      stepTwoForm.subclientId = ""

    };




    const confirmCancelSend = async () => {

      console.log("ffrfr")
      stepIndex.value = 0

      showSend.value = false;
      activeIndex.value = '0';
      reinitializeComponents();
      location.reload()


    };

    const donePayout = async () => {

      console.log("ffrfr")
      stepIndex.value = 0

      sendFundSuccess.value = false


    };

    const handleSendClose = (done: () => void) => {
      ElMessageBox.confirm('Are you sure to cancel this dialog?')
          .then(() => {
            done()
          })
          .catch(() => {
            // catch error
          })
    }


    const {patchErrorRef: approveErrorRef, approveMerchantSettlement} = approveMerchantSettlementRequest();
    const {patchErrorRef, rejectMerchantSettlement} = rejectMerchantSettlementRequest();


    return {
      getIllustrationsPath,
      stepIndex,
      activeIndex,
      showSend,
      stepOneFormRef,
      showSendForm,
      onSubmit,
      handleSelect,
      updatePageSize,
      updatePageNumber,
      loadingRef,
      stepOneForm,
      stepTwoForm,
      handleReceivedAmountChange,
      handleSendAmountChange,
      onToStepTwo,
      onToStepThree,
      stepTwoFormRef,
      stepThirdFormRef,
      stepThirdForm,
      onToStepFour,
      sendFund,
      sendFundSuccess,
      getBalance,
      getAccountBalanceRef,
      clearedAUD,
      pendingAUD,
      refreshPending,
      refreshBalance,
      cancelSend,
      getSendersRef,
      quoteRef,
      dialogVisible,
      handleSendClose,
      confirmCancelSend,
      getRecipientsRef,
      getSubclientsRef,
      handleSelectCurrency,
      donePayout,
      handleChooseSender,
      handleChooseSubclient,
      cfgSenderValue,
      handleChooseCFG


    };
  },

  methods: {}
});
